import React from "react"
import styles from "./callout.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

export default ({ children, ...props }) => {
  const {
    background,
    img,
    imgAlt
  } = props
  return (
    <div className="contact">
      <div className={`${(styles[background] || ``)} ${styles.hero} ${styles.heroSecondary || ``}`}>
        <div className="container">
          <div className={styles.heroContent}>
            {img? <div><img src={img} alt={imgAlt} /></div> : ``}
            <h2 className={`${styles.title} title`}>{props.heading || "Get a membership."}</h2>
            {(props.content ? <p>props.content</p> : ``)}
            {children}
          </div>
          <div className={styles.heroContent}>
            {(props.hasOwnProperty('socials') ? 
            <>
              <a href="https://facebook.com/michael.a.zun" className={styles.social}><FontAwesomeIcon icon={faFacebook}/></a>
              <a href="https://instagram.com/rfdancecenter" className={styles.social}><FontAwesomeIcon icon={faInstagram}/></a>
            </>
            : <a href={props.buttonLink || "/classes/"} className="button is-rounded is-info">{props.buttonText || "See classes"}</a> )}
          </div>
        </div>
      </div>
    </div>
  )
}