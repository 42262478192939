import React from "react"
import Hero from "../components/hero"
import Layout from '../components/layout-default'
import '../styles/layout.scss'
import TeamImg from "../images/team.jpeg"
import demo from "../video/online.mp4"
import Callout from "../components/callout"
import { Link } from "gatsby"

import promoPoster from "../images/highlights/online-promo.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faStar, faMobile } from '@fortawesome/free-solid-svg-icons'

export default () => (
  <Layout
    title="Online Salsa and Bachata Classes | RF Dance"
    description="The best of Orange County's latin dance scene is now available at your fingertips. RF Dance Studio is now offering online dance courses you can get through at your own pace, and at a time that works best for you." 
    pathname="online"
    img={TeamImg}>
    <Hero 
      titleRed="RF"
      titleWhite="Online"
      subtitle="Latin dance, on-demand"
      secondaryBtnTxt="Join on Zoom"
      secondaryBtnLink="/classes"
      secondaryBtnType="link" />
      
    <div className="content-block" style={{paddingLeft: `0`, paddingRight: `0`}}>
      <section>
        <div className="container">
          <h2 className="has-text-centered is-size-2" style={{marginBottom: `1.5em`}}>Take an RF dance course online, at your own pace.</h2>
          <div className="columns is-variable is-8">
            <div className="column is-half">
              <video className="promo-video" poster={promoPoster} controls src={demo}/>
            </div>
            <div className="column is-half">
              <div>
                <p><span>The best of Orange County's latin dance scene is now available at your fingertips.</span> RF Dance Studio is now offering online dance courses you can get through at your own pace, and at a time that works best for you. Best of all, you can improve your dance skills and hone your technique from the comfort and safety of your own home.</p>
                <p className="is-centered-mobile"><a href="https://online.rfdance.com" className="button is-info is-rounded">Go to online courses</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="is-hidden-mobile" style={{backgroundColor: `#f7f7f7`}}>
        <div className="container">
          <div className="columns is-variable is-8">
            <div className="column is-one-third">
              <div className="has-text-centered">
                <div className="icon-thirds"><FontAwesomeIcon icon={faMobile} /></div>
                <h3>Convenience</h3>
                <p>You can access your course content from anywhere, and on any device. Cast your screen to a bigger display for an even better experience.</p>
              </div>
            </div>
            <div className="column is-one-third">
              <div className="has-text-centered">
                <div className="icon-thirds"><FontAwesomeIcon icon={faStar} /></div>
                <h3>Perfectability</h3>
                <p>You can replay a course as much as you'd like, until you're confident you've nailed the technique.</p>
              </div>
            </div>
            <div className="column is-one-third">
              <div className="has-text-centered">
                <div className="icon-thirds"><FontAwesomeIcon icon={faCheckCircle} /></div>
                <h3>Value</h3>
                <p>Gain access to new content being added every week. You'll always be up-to-date with the latest moves in the dance scene.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="zoom" className="inverted" style={{paddingBottom: `8em`}}>
        <div className="container max-70">
          <h2 className="has-text-centered is-size-2" style={{marginBottom: `1.5em`}}>Catch a live class during the week on Zoom!</h2>
          <p className="has-text-centered">Join our live classes online during the week to get your fix with the rest of the team! Classes are fun and lively, and you can join from the comfort and safety of your own home. You can also opt-in for individual attention and critique by turning on your camera, or just as easily practice the moves on your own. It's all about your comfort level.</p>
          <div>
            <table className="table">
              <thead>
                <th>Date</th>
                <th>Time</th>
                <th>Style</th>
              </thead>
              <tr>
                <td>Monday</td>
                <td>8:00pm</td>
                <td><a href="https://us04web.zoom.us/j/4976645105">Bachata</a></td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>8:00pm</td>
                <td><a href="https://us04web.zoom.us/j/4976645105">Bachata</a></td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>8:00pm</td>
                <td><a href="https://us04web.zoom.us/j/4976645105">Salsa</a></td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>8:00pm</td>
                <td><a href="https://us04web.zoom.us/j/4976645105">Cumbia</a></td>
              </tr>
            </table>
            <p style={{textAlign: `center`, margin: `2em auto -2em`}}><Link to="events">Check events calendar</Link></p>
            <p className="is-centered-mobile join-cta"><a href="https://us04web.zoom.us/j/4976645105" className="button is-info is-rounded">Join us on Zoom</a></p>
          </div>
        </div>
      </section>
      <Callout 
        heading="Become an RF Member and save."
        buttonText="Learn more"
        buttonLink="/classes"
      >
        <p>Get instant access to multiple classes each week (including online classes and live classes) by becoming a monthly member. Save time at each class and get a great deal on lessons. $75 monthly, Limited time offer!</p>
      </Callout>
    </div>
    <style jsx="jsx">{`
      section {
        padding-top: 6em;
        padding-bottom: 6em;
      }
      section.inverted {
        background: #171717;
      }
      section.inverted h2,
      section.inverted h3,
      section.inverted p {
        color: #e1e1e1;
      }
      .content-block {
        padding-top: 0;
        padding-bottom: 0;
      }
      .table {
        background: #212020;
        width: 100%;
        margin: 3em auto 0;
        color: #e1e1e1;
        border-radius: 5px;
      }
      .table td,
      .table th {
        border-color: #393939;
      }
      .table td:first-child,
      .table th:first-child {
        padding-left: 2em;
      }
      .table td:last-child,
      .table th:last-child {
        padding-right: 2em;
      }
      .max-70 > * {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      .icon-thirds {
        font-size: 3em;
      }
      .join-cta {
        margin: 3em auto 0;
        text-align: center;
      }
      @media (max-width: 1199px) {
        .container {
          width: 80%;
          margin: 0 auto;
        }
      }
      @media (max-width: 724px) {
        section {
          padding-top: 3em;
          padding-bottom: 2em;
        }
        .container {
          width: 90%;
          margin: 0 auto;
        }
        .content-block h2 {
          font-size: 1.6em !important;
          line-height: 1.4em;
        }
        .is-centered-mobile {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        .promo-video {
          width: 100%;
          height: 100%;
        }
        .max-70 {
          max-width: 90%;
        }
        .max-70 > * {
          max-width: none;
          width: 100%;
        }
      }
    `}</style>
  </Layout>
)